import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Logo from "../../../images/GD_Logo.svg"

const StyledFooter = styled.footer`
    padding: var(--space-5) 0 var(--space-2) 0;
    border-top: 2px solid var(--primary-600);
    background-color: var(--secondary-700);
`

const Container = styled.div`
    width: min(90%, 1200px);
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: var(--secondary-100);
    gap: var(--space-4);

    div {
        display: flex;
        gap: var(--space-1) var(--space-5);
        align-items: flex-start;
        flex-wrap: wrap;
    }

    p {
        max-width: 350px;
        margin: 0;
        font-size: var(--type-xxs);
        font-weight: 300;
    }
`

const Nav = styled.nav`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-2) var(--space-8);

    a {
        font-size: var(--type-xs);
        color: var(--secondary-100);
    }

    a:hover {
        box-shadow: none;
        color: var(--secondary-50);
    }

    h4 {
        grid-column-start: 1;
        grid-column-end: 3;
        font-size: var(--type-s);
    }
`

let year = new Date().getFullYear()

function Footer() {
    return (
        <StyledFooter>
            <Container>
                <div>
                    <img src={Logo} alt="Goddard Digital logo" width={250} />
                    <p>Goddard Digital is a Web Agency & Digital Consultancy based in Leicestershire, UK.</p>
                </div>
                <Nav>
                    <h4>Useful links</h4>
                    <Link to="/">Home</Link>
                    <Link to="/services/">Services</Link>
                    <Link to="/projects/">Projects</Link>
                    <Link to="/contact/">Contact</Link>
                </Nav>
            </Container>
            <Container>
                <p style={{marginTop: 'var(--space-5)', fontWeight: 400}}>{year} © Goddard Digital 💻 ✨</p>
            </Container>
        </StyledFooter>
    )
}

export default Footer