import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"
import { createGlobalStyle } from "styled-components"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"

const GlobalStyle = createGlobalStyle`
    :root {
        /* Type scale */
        --type-xxs: clamp(14px, calc(6px + 0.7vw), 16px);
        --type-xs: clamp(16px, calc(8px + 0.8vw), 19px);
        --type-s: clamp(18px, calc(8px + 1vw), 24px);
        --type-m: clamp(21px, calc(10px + 1.3vw), 32px);
        --type-l: clamp(26px, calc(14px + 1.6vw), 42px);
        --type-xl: clamp(31px, calc(19px + 2vw), 56px);
        --type-xxl: clamp(34px, calc(24px + 2.5vw), 72px);

        /* Colour palette */
        /* Primary */
        --primary-50: #f9f1e3;
        --primary-100: #f0dcb8; /* Link text hover 1 */
        --primary-200: #e7c58c;
        --primary-300: #deaf62;
        --primary-400: #d99f48; /* Link text 1 */
        --primary-500: #d2913a;
        --primary-600: #ce8736; /* Heading text 1 */
        --primary-700: #c77a31;
        --primary-800: #bf6d2d;
        --primary-900: #b15b29; /* Heading text 2 */

        /* Secondary */
        --secondary-50: #e7f0f2;
        --secondary-100: #c9d9db; /* Background 2 */
        --secondary-200: #A9C0C4; /* Regular text 1 */
        --secondary-300: #88a5aa;
        --secondary-400: #6f9198;
        --secondary-500: #567e86;
        --secondary-600: #4b6f75;
        --secondary-700: #3c5b60;
        --secondary-800: #2f474c; /* Regular text 2 */
        --secondary-900: #1f3236; /* Background 1 */

        /* Tertiary */
        --tertiary-50: #e8eaf4;
        --tertiary-100: #c6cbe5;
        --tertiary-200: #a1a9d3;
        --tertiary-300: #7c88c0;
        --tertiary-400: #616db3;
        --tertiary-500: #4654a7;
        --tertiary-600: #404c9d;
        --tertiary-700: #374291;
        --tertiary-800: #303985;
        --tertiary-900: #24276f;

        /* Neutral */
        --neutral-50: hsl(220, 19%, 95%);
        --neutral-100: hsl(220, 19%, 95%);
        --neutral-200: hsl(220, 16%, 85%);
        --neutral-300: hsl(220, 13%, 70%);
        --neutral-400: hsl(220, 10%, 55%);
        --neutral-500: hsl(221, 7%, 45%);
        --neutral-600: hsl(220, 10%, 37%);
        --neutral-700: hsl(220, 13%, 30%);
        --neutral-800: hsl(220, 16%, 25%);
        --neutral-900: hsl(220, 19%, 20%);

        /* Shadows */
        --shadow-color: 191deg 42% 6%;
        --shadow-elevation-low:
            0.2px 0.5px 0.6px hsl(var(--shadow-color) / 0.33),
            0.3px 0.9px 1.1px -1px hsl(var(--shadow-color) / 0.39),
            0.7px 2.2px 2.8px -2px hsl(var(--shadow-color) / 0.44);
        --shadow-elevation-medium:
            0.2px 0.5px 0.6px hsl(var(--shadow-color) / 0.35),
            0.6px 2px 2.5px -0.7px hsl(var(--shadow-color) / 0.39),
            1.4px 4.7px 5.9px -1.3px hsl(var(--shadow-color) / 0.44),
            3.4px 11.1px 13.9px -2px hsl(var(--shadow-color) / 0.48);
        --shadow-elevation-high:
            0.2px 0.5px 0.6px hsl(var(--shadow-color) / 0.37),
            1.4px 4.5px 5.7px -0.3px hsl(var(--shadow-color) / 0.4),
            2.7px 8.7px 10.9px -0.7px hsl(var(--shadow-color) / 0.42),
            4.5px 14.7px 18.4px -1px hsl(var(--shadow-color) / 0.45),
            7.5px 24.2px 30.4px -1.3px hsl(var(--shadow-color) / 0.48),
            12px 38.9px 48.9px -1.7px hsl(var(--shadow-color) / 0.5),
            18.6px 60.4px 75.8px -2px hsl(var(--shadow-color) / 0.53);

        /* Spacing */
        --fixed-space-1: 0.25rem;
        --fixed-space-2: 0.5rem;
        --fixed-space-3: 0.75rem;
        --fixed-space-4: 1rem;
        --fixed-space-5: 1.5rem;
        --fixed-space-6: 2rem;
        --fixed-space-7: 3rem;
        --fixed-space-8: 4rem;
        --fixed-space-9: 6rem;
        --fixed-space-10: 8rem;
        --fixed-space-11: 12rem;
        --fixed-space-12: 16rem;
        --fixed-space-13: 24rem;
        --fixed-space-14: 32rem;
        --fixed-space-15: 40rem;
        --fixed-space-16: 48rem;

        --space-1: calc(0.25rem + 0.2vw);
        --space-2: calc(0.5rem + 0.25vw);
        --space-3: calc(0.75rem + 0.35vw);
        --space-4: calc(1rem + 0.5vw);
        --space-5: calc(1.5rem + 0.75vw);
        --space-6: calc(2rem + 1vw);
        --space-7: calc(3rem + 1.25vw);
        --space-8: calc(4rem + 1.5vw);
        --space-9: calc(6rem + 2vw);
        --space-10: calc(8rem + 2.5vw);
        --space-11: calc(12rem + 3vw);
        --space-12: calc(16rem + 4vw);
        --space-13: calc(24rem + 5vw);
        --space-14: calc(32rem + 7.5vw);
        --space-15: calc(40rem + 10vw);
        --space-16: calc(48rem + 15vw);
    }

    /* Reset begin... */

    *, *::before, *::after {
        box-sizing: border-box;
    }

    * {
        margin: 0;
    }

    html, body, #___gatsby, #gatsby-focus-wrapper {
        height: 100%;
    }

    body {
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        background-color: var(--secondary-900);
        color: var(--secondary-200);
        font-family: "Roboto", serif;
    }

    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }

    input, button, textarea, select {
        font: inherit;
    }

    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }

    /* ...reset end */

    main {
        padding-top: 75px;
        position: relative;
        z-index: 9;
    }

    h1 {
        font-size: var(--type-xxl);
        margin: var(--space-5) 0 var(--space-4) 0;
        line-height: 1.1;
        color: var(--primary-600);
    }

    h2 {
        font-size: var(--type-xl);
        margin: var(--space-4) 0 var(--space-3) 0;
        line-height: 1.1;
        color: var(--primary-600);
    }

    h3 {
        font-size: var(--type-l);
        margin: var(--space-4) 0 var(--space-3) 0;
        line-height: 1.1;
        color: var(--primary-600);
    }

    p {
        font-size: var(--type-xs);
        margin: var(--space-3) 0;
    }

    section {
        padding: var(--space-8) 0;
    }

    a {
        color: var(--primary-400);
        text-decoration: none;
        transition: 300ms;
    }

    a:hover {
        box-shadow: 0 2px 0;
        transition: 300ms;
    }

    .theme-one {
        background-color: var(--secondary-900);
        color: var(--secondary-200);
    }

    .theme-one h1, .theme-one h2 {
        color: var(--primary-600);
    }

    .theme-two {
        background-color: var(--secondary-100);
        color: var(--secondary-800);
    }

    .theme-two h1, .theme-two h2 {
        color: var(--primary-900);
    }

    .theme-two a {
        color: var(--tertiary-500);
        text-decoration: none;
        transition: 300ms;
        
    }

    @media only screen and (min-width: 900px) {
        main {
            padding-top: 0;
        }
    }
`

function Layout({ pageTitle, metaDescription, children }) {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <Helmet>
                { pageTitle ? (<title>{pageTitle} | {data.site.siteMetadata.title}</title>) : (<title>{data.site.siteMetadata.title}</title>) }
                <meta charSet="UTF-8" />
                <html lang="en" />
                <meta name="description" content={metaDescription} />
            </Helmet>
            <GlobalStyle />
            <Header />
            <main>
                {children}
            </main>
            <Footer />
        </>
    )
}

export default Layout