import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Logo from "../../../images/GD_Logo.svg"

const NavContainer = styled.div`
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 5%;
    margin: auto;
    z-index: 100;
    position: fixed;
    background-color: var(--secondary-900);

    a:hover {
        box-shadow: none;
    }

    @media only screen and (min-width: 900px) {
        position: static;
        width: min(90%, 1600px);
        gap: var(--space-4);
    }
`

const Nav = styled.nav`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
        font-size: var(--type-xs);
        padding: var(--space-2) var(--space-3);
    }

    a:hover {
        color: var(--primary-100);
    }

    @media only screen and (max-width: 899px) {
        flex-direction: column;
        position: fixed;
        width: 100%;
        background-color: var(--secondary-900);
        transition: all 300ms ease-in;
        top: 75px;
        left: 0;
        transform: ${props => props.open ? `translateX(0)` : `translateX(100%)`};
        justify-content: flex-start;
        padding: var(--space-3) 5%;

        a {
            padding: var(--space-3) var(--space-2);
            font-size: var(--type-s);
        }
    }
`

const Toggle = styled.div`
    display: flex;
    height: 100%;
    cursor: pointer;
    padding: 0 3vw;

    @media only screen and (min-width: 900px) {
        display: none;
    }
`

const Hamburger = styled.div`
    background-color: var(--primary-600);
    width: 25px;
    height: 2px;
    transition: all 300ms linear;
    align-self: center;
    position: relative;
    transform: ${props => props.open ? `rotate(-45deg)` : `inherit`};

    ::before,
    ::after {
        width: 25px;
        height: 2px;
        transition: all 300ms linear;
        position: absolute;
        content: "";
        background-color: var(--primary-600);
    }

    ::before {
        top: -8px;
        transform: ${props => props.open ? `rotate(-90deg) translate(-8px, 0px)` : `rotate(0deg)`};
    }

    ::after {
        top: 8px;
        opacity: ${props => props.open ? `0` : `1`};
        transform: ${props => props.open ? `rotate(90deg)` : `rotate(0deg)`};
    }
`

function Header() {
    const [navOpen, setNavOpen] = useState(false)

    return (
        <header style={{position: 'relative', zIndex: 100}}>
            <NavContainer>
                <Link to="/">
                    <img src={Logo} alt="Goddard Digital logo" width={250} />
                </Link>
                <Toggle open={navOpen} onClick={() => setNavOpen(!navOpen)} onKeyPress={() => setNavOpen(!navOpen)} aria-label="Main menu" role="button" tabIndex={0}>
                    {navOpen ? <Hamburger open /> : <Hamburger />}
                </Toggle>
                <Nav open={navOpen}>
                    <Link to="/" activeStyle={{color: 'var(--primary-100)'}}>Home</Link>
                    <Link to="/services/" activeStyle={{color: 'var(--primary-100)'}}>Services</Link>
                    {/* <Link to="#" activeStyle={{color: 'var(--primary-100)'}}>About</Link> */}
                    <Link to="/projects/" activeStyle={{color: 'var(--primary-100)'}}>Projects</Link>
                    {/* <Link to="#" activeStyle={{color: 'var(--primary-100)'}}>Blog</Link> */}
                    <Link to="/contact/" activeStyle={{color: 'var(--primary-100)'}}>Contact</Link>
                </Nav>
            </NavContainer>
        </header>
    )
}

export default Header